import React from "react";
import StruggleCard from "./StruggleCard";
import image1 from "../../Asset/message.png";
import image2 from "../../Asset/grammer.png";
import image3 from "../../Asset/time trap.png";
import image4 from "../../Asset/language barriers.png";
import image5 from "../../Asset/voice.png";
import image6 from "../../Asset/consistency.png";
import image7 from "../../Asset/Plagiarism.png";

const RightBorder = () => {
  return (
    <div
      style={{
        backgroundImage: "radial-gradient(circle, #999999 0%, #101010 100%)",
      }}
      className="w-[1px] lg:h-[12rem] md:h-[10rem] max-md:h-[9rem] bg-white  "
    >
      <span className="hidden">v</span>
    </div>
  );
};

const Struggles = () => {
  const hoverGradientTopSection =
    "hover:bg-gradient-to-b hover:from-[#000000bb]/20 hover:to-[#ff404079]/20 ";
  const hoverGradientLowerSection =
    "hover:bg-gradient-to-b hover:from-[#ff404079]/20 hover:to-[#000000bb]/20 ";

  return (
    <div className="md:flex justify-center items-center w-full flex-col gap-28 lg:mt-40 mt-28  hidden">
      <div className="flex justify-center items-center">
        <p className="lg:text-[3rem] md:text-[2rem] text-[1.3rem]  font-medium">
          Do You{" "}
          <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
            Struggle
          </span>{" "}
          With ?
        </p>
      </div>
      <div className="w-full">
        <div className="flex justify-center items-center w-full">
          <StruggleCard
            image={image1}
            text="Typing long messages, emails and"
            text1="documents, accurately and quickly?"
            hover={hoverGradientTopSection}
          />
          <RightBorder />

          <StruggleCard
            image={image2}
            text="Constantly correcting grammar,"
            text1={"punctuation, and formatting  errors?"}
            hover={hoverGradientTopSection}
          />
          <RightBorder />

          <StruggleCard
            image={image3}
            text="Spending too much time editing or"
            text1={"rewriting content?"}
            hover={hoverGradientTopSection}
          />
          <RightBorder />

          <StruggleCard
            image={image4}
            text="Facing language barriers with voice"
            text1={"transcriptions?"}
            hover={hoverGradientTopSection}
          />
        </div>
        <div
          style={{
            backgroundImage: "radial-gradient(circle,#999999,#101010 )",
          }}
          className="h-[1.2px] lg:w-[90%] mx-auto"
        ></div>
        <div className="flex justify-center items-center">
          <StruggleCard
            image={image5}
            text="Dealing with inconsistent voice"
            text1={"typing accuracy?"}
            hover={hoverGradientLowerSection}
          />
          <RightBorder />

          <StruggleCard
            image={image6}
            text="Maintaining consistency in tone and"
            text1={"style across different types of writing?"}
            hover={hoverGradientLowerSection}
          />
          <RightBorder />

          <StruggleCard
            image={image7}
            text="Plagiarism concerns when writing"
            text1={"essays or content?"}
            hover={hoverGradientLowerSection}
          />
        </div>
      </div>
    </div>
  );
};

export default Struggles;
