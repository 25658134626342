import React, { useState } from "react";
import student from "../../Asset/students.png";
import business from "../../Asset/Business.png";
import healthcare from "../../Asset/healthcare.png";
import creators from "../../Asset/content creater.png";
import legal from "../../Asset/Legal pro.png";
import legalImage from "../../Asset/Legal Professionals.png";
import studentImage from "../../Asset/Students.jpeg";
import businessImage from "../../Asset/Business Professionals.png";
import healthcareImage from "../../Asset/Healthcare Professionals.png";
import creatorsImage from "../../Asset/Content Creators.png";

const EveryOne = () => {
  const [activeTab, setActiveTab] = useState("business");

  const tabs = [
    {
      id: "business",
      label1: "Business Professionals",
      icon: business,
      heading1: "Boost Your Productivity, ",
      heading2: "Minimize Your Typing ",
      image: businessImage,
      subHeading: "Save time on reports and emails",
      content:
        "ZeroTyping AI allows business professionals to quickly dictate reports, emails, and presentations, transforming spoken words into polished text in real-time. By eliminating the need for manual typing, you can focus on high-priority tasks. With smart grammar tools and tone adjustment, your writing will always be professional and error-free, helping you get more done in less time.",
    },
    {
      id: "students",
      label1: "Students",
      icon: student,
      heading1: "Master Your Assignments  ",
      heading2: "Without Typing,",
      image: studentImage,
      subHeading: "Dictate essays, take notes, check for plagiarism",
      content:
        "Students, get ready to breeze through your assignments with ZeroTyping AI. Use your voice to write essays, research papers, and reports, while our AI ensures accurate grammar and plagiarism-free content. The built-in plagiarism checker gives you peace of mind that your work is original. Whether you’re in a lecture or at the library, you can focus on your ideas while we handle the typing. Cross-device sync means you can pick up where you left off, anytime. Work smarter, not harder.",
    },
    {
      id: "healthcare",
      label1: "Healthcare Professionals",
      icon: healthcare,
      heading1: "Effortlessly Document ",
      heading2: "Patient Notes On-the-Go",
      image: healthcareImage,
      subHeading: "Dictate medical notes with precision",
      content:
        "For healthcare professionals, time is crucial. ZeroTyping AI lets you dictate patient notes and reports hands-free, ensuring accurate transcription every time. With cross-device compatibility, you can seamlessly document on your phone or computer without breaking the flow of your busy day. Our personalised dictionary ensures that medical terms and patient names are always spelled correctly.",
    },
    {
      id: "creators",
      label1: "Content Creators",
      icon: creators,
      heading1: "Create Content Faster,  ",
      heading2: "with Flawless Accuracy",
      image: creatorsImage,
      subHeading: "Dictate blogs, social posts, and articles",
      content:
        "Content creators and bloggers can now bring their ideas to life faster than ever with ZeroTyping AI. Speak your blog posts, articles, or social media captions, and watch them turn into well-structured, error-free text. With built-in grammar checks and tone adjustments, you can fine-tune your content to match your audience’s needs—whether professional or casual.",
    },
    {
      id: "legal",
      label1: "Legal Professionals",
      icon: legal,
      heading1: "Draft Legal Documents ",
      heading2: "Without Lifting a Finger",
      image: legalImage,
      subHeading: "Save time on reports and emails",
      content:
        "ZeroTyping AI takes the hassle out of legal documentation by converting your spoken words into precise, structured legal text. Whether you’re drafting contracts, legal briefs, or correspondence, you’ll benefit from our personalised dictionary that recognises complex legal terms. Dictate, review, and finalise documents quickly and accurately across all your devices, saving time and ensuring accuracy.",
    },
  ];

  return (
    <div className=" flex flex-col w-full mb-36 mt-64">
      <div className="flex flex-col gap-6 ">
        <div className="text-center lg:text-[3rem] md:text-[2rem] text-[1.3rem] font-medium">
          AI Typing for{" "}
          <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
            Everyone
          </span>
        </div>
        <div
          style={{
            lineHeight: "3rem",
          }}
          className="text-center text-[2rem]"
        >
          ZeroTyping AI makes writing easy for everybody, <br /> so you can
          focus on what really matters. ?
        </div>
        <div className="lg:w-[90%] w-[95%] mx-auto mt-10">
          <div className={`flex justify-between items-center ${activeTab} `}>
            {tabs.map((tab, i) => (
              <div key={i} className="flex flex-col  w-[18.5%]  ">
                <div
                  className={`flex justify-center items-center w-full   rounded-[1rem] lg:h-[12rem] h-[9.25rem] ${
                    activeTab === tab.id
                      ? "bg-[#222222]  border border-[#888888]/50 border-b-0 rounded-b-none "
                      : "bg-[#313131] lg:mb-6 mb-3 z-10"
                  }`}
                >
                  <div
                    // style={{ height: "calc(100% - 2.5rem)" }}
                    onClick={() => setActiveTab(tab.id)}
                    className={`flex justify-between lg:py-3 py-2 cursor-pointer  items-center h-[calc(100%-1.75rem)] lg:h-[calc(100%-2.5rem)] flex-col lg:gap-2 gap-0 ${
                      activeTab === tab.id ? "" : "bg-[#222222]"
                    }  rounded-[1rem] lg:m-5 m-3 w-full`}
                  >
                    <div
                      className={`lg:w-20 w-16  lg:h-[4.5rem] h-[3.5rem] rounded-lg p-[1px] ${
                        activeTab === tab.id
                          ? "bg-gradient-to-t from-[#407BFF20] to-[#5ECCFF90]"
                          : "bg-gradient-to-t from-[#FFFFFF0D] to-[#FFFFFF80]"
                      }`}
                    >
                      <div className=" bg-[#313131] w-full h-full rounded-lg ">
                        <div className="flex justify-center items-center h-full ">
                          <img className="lg:w-9 w-6 " src={tab.icon} alt="" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center justify-center h-full ">
                        <span className="w-[90%] text-center lg:text-[1rem] text-[.8rem] capitalize font-semibold">
                          {tab.label1}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {activeTab === tab.id && (
                  <div
                    className={`flex justify-between bg-[#222222]   border-[#888888]/50 lg:h-[2.5rem] h-[1.75rem] mb-[-14.4px] mt-[-1.6px] ${
                      i === 0
                        ? "border-l ml-[0px] lg:w-[calc(100%+24px)] w-[calc(100%+12px)]"
                        : `lg:ml-[-24px] ml-[-12px] ${
                            i === tabs.length - 1
                              ? "border-r lg:w-[calc(100%+24px)] w-[calc(100%+12px)]"
                              : "lg:mr-[-24px] mr-[-12px] lg:w-[calc(100%+48px)] w-[calc(100%+24px)]"
                          }`
                    } 
                        `}
                    // ${
                    //   i === tabs.length - 1
                    //     ? "border-r w-[calc(100%+24px) "
                    //     : "mr-[-24px]"
                    // }
                  >
                    <div
                      className={`left lg:h-[26.6px] h-[14.6px] mb-[-1px] lg:w-[25px] w-[13px]  ${
                        i === 0
                          ? " "
                          : "border-b border-r rounded-br-[1rem] border-[#888888]/50 lg:bg-[#111111] bg-[#1a1a1a] "
                      } `}
                    ></div>

                    <div
                      className={`right lg:h-[26.6px] h-[14.6px] mb-[-1px] lg:w-[25px] w-[13px]  ${
                        i === tabs.length - 1
                          ? ""
                          : "border-l border-b rounded-bl-[1rem] border-[#888888]/50 lg:bg-[#111111] bg-[#1a1a1a]"
                      } `}
                    ></div>
                  </div>
                )}
              </div>
            ))}
          </div>

          {tabs.map((tab) =>
            activeTab === tab.id ? (
              <div
                key={tab.id}
                className={`border border-[#888888]/50 p-4 lg:px-8 px-4 rounded-[1rem]   bg-[#222222]`}
              >
                <div className="flex lg:p-4 p-2 justify-between  ">
                  <div className="flex-1 flex flex-col items-start lg:gap-3 gap-2 ">
                    <h2 className="font-medium lg:text-[2rem] text-[1.25rem] ">
                      <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
                        {" "}
                        {tab.heading1}
                      </span>
                      {tab.heading2}
                    </h2>
                    <p className=" lg:text-[1.5rem] text-[1rem] font-medium z-0 animate-slideInFromLeft">
                      {tab.subHeading}
                    </p>
                    <p className="lg:text-[1rem] text-[.8rem] md:w-[88%] w-[94%] leading-8">
                      {tab.content}
                    </p>
                  </div>
                  <div className="w-[35%] flex justify-end items-center">
                    {" "}
                    <img
                      className="w-full rounded-[1rem] lg:h-[16rem] h-[12rem] border  border-[#888888]/80 bg-cover"
                      src={tab.image}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};

export default EveryOne;
