import React from "react";
import dasboard from "../../Asset/dasboard.png";
import AutomationAi from "../../Asset/Automation & Ai.png";
import Integrations from "../../Asset/Integrations.png";
import Grammar from "../../Asset/Grammar Checker.png";
import Plagiarism from "../../Asset/Plagiarism Checker.png";
import Paraphraser from "../../Asset/Paraphraser.png";
import AITranslation from "./AITranslation";
import Marquee from "react-fast-marquee";

const Dasboard = () => {
  return (
    <div className="flex justify-center items-center flex-col gap-12 lg:my-36 md:my-28 my-20">
      <h1 className="text-center lg:text-[3rem] md:text-[2rem] text-[1.3rem] font-medium my-10">
        Type Less,{" "}
        <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
          Do More
        </span>{" "}
        - The AI <br /> that works for you.  ?
      </h1>
      <div>
        <div className="w-full flex justify-center items-center relative">
          <div className="w-[75%]">
            <img
              src={dasboard}
              className="rounded-2xl"
              style={{
                boxShadow:
                  "0 0 200px rgba(94, 204, 255, 0.175), 0 0 100px rgba(94, 204, 255, 0.1), 0 0 30px rgba(94, 204, 255, 0.1)",
              }}
              alt=""
            />
          </div>
          <div className="w-[27%] h-[27%] flex flex-col justify-between items-start  bg-[#161616] border-[#747474]/50 border-[.1px] rounded-[12px]  text-black absolute top-[-6%] left-[37%]">
            <p className="bg-[#313131] lg:text-[14px] md:text-[10px] max-md:text-[7px] text-[6px] font-medium w-max lg:m-3 md:m-2 m-1 text-[white] lg:px-6 md:px-4 max-md:px-2 px-1 lg:py-1 md:py-[3px] py-[1px] rounded-full">
              Features
            </p>

            <Marquee direction="left" className="text-white  ">
              {/* large */}
              <div className="hidden md:flex gap-3 lg:mb-3 md:mb-2 mb-1 lg:text-[14px] md:text-[10px] max-md:text-[7px] text-[6px] font-medium">
                <div className="flex ml-3 justify-center items-center gap-4 border-[#DFE5F9]/10 border lg:px-8 px-6 lg:py-3 md:py-2 py-[6px] sm:rounded-xl rounded">
                  <img
                    className="lg:w-7 md:w-6 max-md:w-5 w-3"
                    src={Plagiarism}
                    alt="Plagiarism Checker"
                  />
                  <p>Plagiarism Checker</p>
                </div>
                <div className="flex  justify-center items-center gap-4 border-[#DFE5F9]/10 border lg:px-8 px-6 lg:py-3 md:py-2 py-[6px] sm:rounded-xl rounded">
                  <img
                    className="lg:w-8 md:w-7 max-md:w-6 w-4"
                    src={Paraphraser}
                    alt="Paraphraser"
                  />
                  <p>Paraphraser</p>
                </div>

                <div className="flex  justify-center items-center gap-4 border-[#DFE5F9]/10 border lg:px-8 px-6 lg:py-3 md:py-2  py-[6px] sm:rounded-xl rounded">
                  <img
                    className="lg:w-7 md:w-6 max-md:w-5 w-3"
                    src={Grammar}
                    alt="Grammar Checker"
                  />
                  <p>Grammar Checker</p>
                </div>
              </div>
              {/* samll */}
              <div className="text-white md:hidden block ">
                <div className="flex gap-2  mb-1  text-[6px] font-medium">
                  <div className="flex ml-2 justify-center items-center gap-2 border-[#DFE5F9]/10 border  py-[4px] px-2 rounded">
                    <img
                      className=" w-3"
                      src={Plagiarism}
                      alt="Plagiarism Checker"
                    />
                    <p>Plagiarism Checker</p>
                  </div>
                  <div className="flex  justify-center items-center gap-2 border-[#DFE5F9]/10 border  py-[4px] px-2 rounded">
                    <img className="w-3" src={Paraphraser} alt="Paraphraser" />
                    <p>Paraphraser</p>
                  </div>

                  <div className="flex  justify-center items-center gap-2 border-[#DFE5F9]/10 border   py-[4px] px-2 rounded">
                    <img className=" w-3" src={Grammar} alt="Grammar Checker" />
                    <p>Grammar Checker</p>
                  </div>
                </div>
              </div>
            </Marquee>
          </div>
          <div className="w-[27%] h-[45%] flex flex-col justify-between items-start  bg-[#161616] border-[#747474]/50 border-[.1px] rounded-[12px]  text-black absolute bottom-[-20%] right-[20%]">
            <AITranslation />
          </div>
          <div className=" w-[22%] absolute top-[47.5%] left-[5%]">
            <img src={AutomationAi} alt="" />
          </div>
          <div className=" w-[25%] absolute top-[20%] right-[6%]">
            <img src={Integrations} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dasboard;
