import Hero from "./component/hero/Hero";
import Navbar from "./component/Navbar/Navbar";
import VideoSection from "./component/VideoSection/VideoSection";
import "./App.css";
import LanchingSection from "./component/LanchingSection/LanchingSection";
import Struggles from "./component/Struggles/Struggles";
import Footer from "./component/Footer/Footer";
import ScrollAnimation from "./component/ScrollAnimation/ScrollAnimation";
import Dasboard from "./component/Dasboard/Dasboard";
import EveryOne from "./component/EveryOne/EveryOne";
import StrugglesSm from "./component/Struggles/StrugglesSm";

function App() {
  return (
    <div
      style={{
        backgroundImage:
          "radial-gradient(circle, rgba(52, 52, 52, 0.5) 50%, black 100%)",
        backgroundSize: "cover",
      }}
      className="bg-black  min-h-screen text-white text overflow-y-auto scrollbar-hide"
    >
      <Navbar />
      <Hero />
      <VideoSection />
      <LanchingSection />
      <Struggles />
      <StrugglesSm />
      <ScrollAnimation />
      <Dasboard />
      <div className=" hidden md:block">
        <EveryOne />
      </div>
      <Footer />
    </div>
  );
}

export default App;
