import React, { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ScrollAnimation = () => {
  useEffect(() => {
    gsap.utils.toArray(".paragraph").forEach((paragraph) => {
      gsap.fromTo(
        paragraph,
        { scale: 0.8, opacity: 0 },
        {
          scale: 1,
          opacity: 1,
          duration: 2,
          scrollTrigger: {
            trigger: paragraph,
            start: "top 100%",
            end: "top 50%",
            scrub: true,
          },
        }
      );
    });
  }, []);

  // useEffect(() => {
  //   const paragraphs = gsap.utils.toArray(".paragraph");

  //   paragraphs.forEach((paragraph, index) => {
  //     gsap.fromTo(
  //       paragraph,
  //       { scale: 1.4, opacity: 0 },
  //       {
  //         scale: 1,
  //         opacity: 1,
  //         duration: 2,
  //         scrollTrigger: {
  //           trigger: paragraph,
  //           start: `top+=${index + 1 * 200} 10%`, // Start animation when top of the paragraph is in view
  //           end: "top 0%", // End animation at the middle of the paragraph
  //           scrub: true, // Smooth transition as you scroll
  //         },
  //         delay: index * 2, // Add delay for each paragraph based on its index
  //       }
  //     );
  //   });
  // }, []);
  return (
    <div className="flex justify-center items-center flex-col lg:leading-[4.3rem] md:leading-[3rem] leading-[2rem] lg:my-28 md:my-20 my-16 lg:text-[1.75rem] md:text-[1.4rem] text-[.7rem] lg:gap-14 md:gap-12 gap-10 ">
      <p className="text-center font-normal paragraph">
        <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
          ZeroTyping AI
        </span>{" "}
        turns your voice into perfectly transcribed text <br /> with unmatched
        accuracy, every time.
      </p>
      <p className="text-center font-medium paragraph">
        and it doesn't stop there —{" "}
      </p>
      <p className="text-center font-normal paragraph">
        Integrate ChatGPT’s powerful AI to refine your output based on your own{" "}
        <br />
        custom preferences. Whether it's tone, grammar, or even your own <br />
        customised dictionary, {" "}
        <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
          ZeroTyping AI
        </span>{" "}
        delivers the exact output you need.
      </p>
    </div>
  );
};

export default ScrollAnimation;
