import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <div className="flex flex-col justify-center items-center md:mt-24 mt-16 mx-auto">
      <h1 className="lg:text-[3rem] md:text-[2rem] text-[1.1rem] font-medium  md:w-[80%] w-[90%] text-center ">
        <p>
          State-of-the-art{" "}
          <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
            Voice Typing
          </span>{" "}
          powered by
        </p>{" "}
        <p>ChatGPT and Whisper AI</p>
      </h1>
      <div className="container lg:my-10 md:my-6 my-12  lg:text-[1.5rem] md:text-[1rem] text-[.6rem]  flex items-center justify-center">
        <div className="animation overflow-hidden md:h-[100px] h-[55px] flex flex-col items-center lg:gap-20 md:gap-14 gap-9">
          <div className="first">
            <div className="text-center ">
              <p className="block">Flawless voice typing!</p>
              <p className="block">
                {" "}
                Simply speak, and let our AI handle the rest.
              </p>
            </div>
          </div>
          <div className="second">
            <div className=" text-center">
              <p>
                Powered by the precision of Whisper API and enhanced by the
                intelligence
              </p>
              <p>
                of ChatGPT, your voice becomes flawlessly formatted text—every
                time
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
