import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";

const Contact = () => {
  return (
    <div className="flex justify-center  items-center flex-row lg:gap-4 md:gap-2.5 gap-2">
      <input
        className="bg-[#313131] lg:w-[full] md:w-[75%] w-[50%] md:text-[1rem] text-[.8rem] text-white py-2 px-3 border border-[#A9A9A9] outline-none md:rounded-[1.2rem] rounded-xl lg:h-[4rem] md:h-[3rem] h-2rem"
        type="text"
        placeholder="First Name"
      />
      <input
        className="bg-[#313131] lg:w-[full] md:w-[75%] w-[50%] md:text-[1rem] text-[.8rem] text-white py-2 px-3 border border-[#A9A9A9] outline-none md:rounded-[1.2rem] rounded-xl lg:h-[4rem] md:h-[3rem] h-2rem"
        type="email"
        name=""
        id=""
        placeholder="Email"
      />
      <button className="flex items-center justify-center lg:gap-3 md:gap-2 gap-1 lg:h-[4rem] md:h-[3rem] h-2rem md:py-0 py-[10px] lg:w-full w-[80%] md:rounded-[1.2rem] rounded-xl bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] lg:px-6 md:px-2 px-1 lg:text-[1.2rem] md:text-[.9rem] text-[.7rem] font-medium">
        Join our Waiting List!
        <FaArrowRightLong />
      </button>
    </div>
  );
};

export default Contact;
