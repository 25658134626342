import React from "react";

const VideoSection = () => {
  return (
    <div className="w-full">
      <video
        className="bg-transparent w-full"
        src="/video/NEW-DASHBOARD1.webm"
        autoPlay
        loop
        muted
      />
    </div>
  );
};

export default VideoSection;
