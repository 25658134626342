import React from "react";
import micLogo from "../../Asset/Artboard 1 4.png";
import zeroTypingLogo from "../../Asset/Group 1321323108.png";

const Navbar = () => {
  return (
    <div className="md:relative fixed w-full backdrop-blur-md backdrop-opacity-80 z-50">
      <div className="flex items-center justify-between  lg:mx-10 mx-4 lg:py-8 py-4 ">
        <div className="flex md:gap-3 gap-2 items-center ">
          <img src={micLogo} className=" lg:h-12 md:h- 9 h-5" alt="" />
          <img src={zeroTypingLogo} className=" lg:h-14 md:h-10 h-6" alt="" />
        </div>
        <div>
          <button className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] text-white lg:py-4 md:py-3 py-2 lg:px-10 md:px-7 px-5 md:rounded-[10px] rounded-[7px]  lg:text-[1rem] md:text-[.8rem] text-[.65rem] font-medium outline-none">
            Join our Waiting List!
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
