import React from "react";

import Contact from "../contact/Contact";
import clock from "../../Asset/Clock GIF.gif";
import { FaArrowRightLong } from "react-icons/fa6";

const LanchingSection = () => {
  return (
    <div className="flex justify-center flex-col items-center lg:my-10 md:my-8 my-6 lg:gap-6 md:gap-5 gap-4">
      <div>
        <img
          src={clock}
          className="lg:w-[18rem] md:w-[14rem] w-[10rem]"
          alt=""
        />
      </div>
      <div className="flex justify-center items-center flex-col lg:gap-8 md:gap-6 gap-4">
        <p className="lg:text-[3rem] md:text-[2rem] text-[1.5rem] font-medium text-center">
          <span className="bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] bg-clip-text text-transparent">
            Launching
          </span>{" "}
          Soon!
        </p>
        <p className="text-center lg:text-[2rem] md:text-[1.5rem] text-[.9rem] ">
          Join our waiting list today, and be the first to test{" "}
          <p>our exciting new productivity solution.</p>
        </p>
      </div>
      <div>
        <div className="flex justify-center md:mx-0 mx-8 items-center flex-row lg:gap-4 md:gap-2.5 gap-1.5">
          <input
            className="bg-[#313131] lg:w-[full]  md:text-[1rem] text-[.7rem] w-[50%] text-white py-2 px-3 border border-[#A9A9A9] outline-none md:rounded-[1.2rem] rounded-xl lg:h-[4rem] md:h-[3rem] h-2rem"
            type="text"
            placeholder="First Name"
          />
          <input
            className="bg-[#313131] lg:w-[full]  md:text-[1rem] text-[.7rem] w-[50%] text-white py-2 px-3 border border-[#A9A9A9] outline-none md:rounded-[1.2rem] rounded-xl lg:h-[4rem] md:h-[3rem] h-2rem"
            type="email"
            name=""
            id=""
            placeholder="Email"
          />
          <button className="flex items-center justify-center lg:gap-3 md:gap-2 gap-1 lg:h-[4rem] md:h-[3rem] h-2rem md:py-0 py-2 w-[80%] md:rounded-[1.2rem] rounded-xl bg-gradient-to-r from-[#5ECCFF] to-[#407BFF] lg:px-6 md:px-2 px-1 lg:text-[1.2rem] md:text-[.9rem] text-[.7rem] font-medium">
            Join our Waiting List!
            <FaArrowRightLong />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LanchingSection;
