import React from "react";

const StruggleCard = ({ image, text, hover, text1 }) => {
  return (
    <div
      className={`${hover} bg-transparent md:w-[22%] lg:h-[12rem] md:h-[10rem] max-md:h-[9rem] flex justify-center items-end pb-12`}
    >
      <div className="flex justify-center items-center flex-col mx-auto gap-4">
        <div className="text-[#B7B7B7] text-center">
          <img src={image} className="lg:w-7 md:w-6 max-md:w-5" alt="" />
        </div>
        <div className="text-center lg:text-[1rem] md:text-[.7rem] max-md:text-[.5rem] font-normal px-2 flex flex-col">
          <span>{text}</span>
          <span>{text1}</span>
        </div>
      </div>
    </div>
  );
};

export default StruggleCard;
